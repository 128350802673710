export class HeaderHeroBackground {
    constructor(target: string) {
        const targetHero = document.querySelector(target) as HTMLElement;

        this.setHeroHandler(targetHero);
    }

    setHeroHandler(targetHero) {
        const siteHeader = document.querySelector('#site-header');

        // controls if site header should display PDP hero theme based on if hero is within view
        function handleInView(entries, observer) {
            let heroVisible = true

            entries.forEach(entry => {
                heroVisible = entry.isIntersecting;

                if (heroVisible) {
                    siteHeader.classList.add('theme-overwrite')
                } else {
                    siteHeader.classList.remove('theme-overwrite')
                }
            });
        }

        // only set up intersection observer if site header theming is active
        if (siteHeader.getAttribute('data-theme-overwrite') === 'true') {
            let options = {
                rootMargin: '0px',
                threshold: 0
            }

            let observer = new IntersectionObserver(handleInView, options);

            observer.observe(targetHero);
        }
    }
}