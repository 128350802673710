import { SvgSprite } from '../modules/svg-loader';
import { HeaderHeroBackground } from '../modules/header-hero-background';

function xhrRequest(url: string, requestType: string, successFunction?: Function, errorFunction?: Function) {
    let xhttp = new XMLHttpRequest();
    xhttp.withCredentials = true;
    xhttp.onreadystatechange = () => {
        if (xhttp.readyState == 4 && xhttp.status == 200) {
            //success!
            if (successFunction) {
                if (requestType === "POST")
                    successFunction(xhttp.responseURL);
                else
                    successFunction(xhttp.responseText);
            }
        }
        else {
            if (errorFunction) {
                errorFunction();
            }
        }
    };
    xhttp.open(requestType, url, true);
    if (requestType === "POST")
        xhttp.setRequestHeader('Content-Type', 'text/html');
    else
        xhttp.setRequestHeader('accept', 'application/json');
    xhttp.send();
}

function nodeListToArray<T>(nodes: NodeList): Array<T> {
    return [].slice.call(nodes);
}

 function hideElements(array: Array<HTMLElement>) {
    array.forEach(el => {
        el.classList.add('hide');
    });
}

function showElements(array: Array<HTMLElement>) {
    array.forEach(el => {
        el.classList.remove('hide');
    });
}

export function loginState() {
    let loginStateRequestSuccess = function (responseText) {
        let response = JSON.parse(responseText);
        let loggedOutLinks = nodeListToArray<HTMLElement>(document.querySelectorAll('.nav-utility-loggedout-js'));
        let loggedInLinks = nodeListToArray<HTMLElement>(document.querySelectorAll('.nav-utility-loggedin-js'));
        let linksToHide = loggedInLinks;
        let linksToShow = loggedOutLinks;

        let isLoggedIn = response.loggedin;
        let count = response.count;

        if (isLoggedIn) {
            linksToHide = loggedOutLinks;
            linksToShow = loggedInLinks;
        }

        hideElements(linksToHide);
        showElements(linksToShow);

        if (count != 0) {
            let cartText = nodeListToArray<HTMLElement>(document.querySelectorAll('.cart-count-js'));
            cartText.forEach(el => {
                el.innerText = `${count}`;
                el.classList.remove('hide');
            });
        }

    }
    xhrRequest((window as any).baseUrl + '/api/session/IsLoggedIn', "GET", loginStateRequestSuccess);
}

function injectSpanElement(nodeList) {
    if (nodeList) {
        let containersArray = Array.prototype.slice.call(nodeList);

        containersArray.forEach(function (item) {
            if (!item.querySelector('.faux-label')) {
                let label = item.querySelector('label');
                let fauxLabel = document.createElement('span');

                fauxLabel.classList.add('faux-label');

                while (label.lastChild && label.lastChild.nodeType === 3) {
                    fauxLabel.appendChild(label.lastChild);
                }

                label.appendChild(fauxLabel);
            }
        });
    }
}

(() => {
    // Request SVG sprite
    let spritePath = (window as any).SVG_SPRITE_PATH || '/Static/img/svg-sprite.svg';
    new SvgSprite(spritePath);
    loginState();

    new HeaderHeroBackground('.page-pdp-hero');

    window.addEventListener('load', () => {
        // Umbraco Forms
        const checkboxContainers = Array.prototype.slice.call(document.querySelectorAll('.umbraco-forms-field.checkbox'));

        if (checkboxContainers) {
            checkboxContainers.forEach(checkboxContainer => {
                const checkboxLabel = checkboxContainer.querySelector('.umbraco-forms-label');
                const checkboxInner = checkboxContainer.querySelector('.umbraco-forms-field-wrapper');
                const checkbox = checkboxInner.querySelector('input[type="checkbox"]');

                if (checkboxLabel) {
                    checkboxInner.insertBefore(checkboxLabel, checkbox.nextSibling);
                }
            })
        }

        const tnewPage = document.querySelector('.tnew-wrapper');

        if (tnewPage) {
            let selectElements = Array.prototype.slice.call(document.querySelectorAll('select.form-control'));

            if (selectElements) {
                selectElements.forEach(element => {
                    let outerDiv = document.createElement('div');
                    outerDiv.classList.add('styled-select');

                    element.parentNode.insertBefore(outerDiv, element.parentNode.firstElementChild.nextSibling);

                    outerDiv.appendChild(element);
                })
            }

            // Adding a <span> tag after radio button and
            // checkbox input fields for styling purposes

            let radioBtnContainers = document.querySelectorAll('.radio');
            let checkboxContainers = document.querySelectorAll('.checkbox');

            if (radioBtnContainers != null) {
                injectSpanElement(radioBtnContainers);
            }

            if (checkboxContainers != null) {
                injectSpanElement(checkboxContainers);
            }

            // Cart page modal checkbox
            let cartDetailsPage = document.querySelector('.tn-cart-details-page');

            if (cartDetailsPage) {
                let removeButtons = Array.prototype.slice.call(cartDetailsPage.querySelectorAll('.tn-cart__btn-remove'));

                if (removeButtons) {
                    removeButtons.forEach(button => {
                        button.addEventListener('click', function () {
                            window.setTimeout(function () {
                                let checkboxContainers = document.querySelectorAll('.checkbox');

                                if (checkboxContainers != null) {
                                    injectSpanElement(checkboxContainers);
                                }
                            }, 100);
                        })
                    })
                }
            }
        }
    })
})();
